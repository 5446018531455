import { ajaxDelete, updateUI } from 'static/js/ajax'

function report_link(format) {
    var report_url = $(".datatable").DataTable().ajax.url();

    if(report_url.indexOf("?") > -1) {
        report_url = report_url+"&report=true&format=";
    } else {
        report_url = report_url+"?report=true&format=";
    }
    report_url += format;

    if($(".datatable").DataTable().order().length > 0) {
        report_url += "&order[0][dir]=" + $(".datatable").DataTable().order()[0][1] + "&order[0][column]=" + $(".datatable").DataTable().order()[0][0];
    }

    report_url += "&search[value]=" + $(".datatable").DataTable().search();
    window.location.href = report_url;
}

//
// Manually append first thead row for distribution table
//
$(function() {
  if($('.table-distribution .dataTable').length) {
    var table = $('.table-distribution .dataTable').DataTable();

    new $.fn.dataTable.FixedHeader( table, {
      header: true,
      headerOffset: $('.navbar').outerHeight()
    });

    var counters = {static: {counter: 0, displayName: ""}}
    var firstHeaderRow = $('<tr role="row"></tr>');

    $(table.columns().header()).each(function (index, element) {
      let dataValue = $(element).data("header-group")
      if (dataValue) {
        if (counters[dataValue]) {
          counters[dataValue]["counter"]++
        } else {
          counters[dataValue] = {counter: 1, displayName: $(element).data("header-name")}
        }
      } else {
        counters["static"]["counter"]++
      }
    });

    for (const [key, value] of Object.entries(counters)) {
      if (key == "static") {
        firstHeaderRow.append(`<th colspan="${value.counter}" class="text-center">${gettext('Consumer Data')}</th>`);
      } else {
        firstHeaderRow.append(`<th colspan="${value.counter}" class="text-center ${key}-column" data-type="${key}">${value.displayName}</th>`);
      }
    }

    var headerRow = $('tr', table.table().header());
    firstHeaderRow.insertBefore(headerRow);
  }
});

//
// Show / Hide dataTables column groups base on nav links for distribution table
//
$( document ).ready(function() {
  if($('.table-distribution .dataTable').length) {
    let table = $('.table-distribution .dataTable').DataTable();
    let allColumns = table.columns()[0];
    let activeMeterTabs = allActiveMeterColumsGroup();

    if (!activeMeterTabs.includes('all')) {
      showTableMetersColumns(table, activeMeterTabs, allColumns);
    }

    $('.meter-select-column-groups .meter').on('click', function (e) {
      // Toggles push state. Temporarily adds .active class
      $(e.currentTarget).button('toggle');

      let meterTypeClicked = $(e.currentTarget).find('input').val();

      if (meterTypeClicked == "all") {
        // remove active class for others
        $(".meter-select-column-groups .active").removeClass("active");

        // show all columns
        showTableMetersColumns(table, allMeterColumsGroup(), allColumns);

        // save active state in backend
        updateUI(JSON.stringify({'meters_active_tabs': 'all'}));
      } else {
        // remove .active from All
        $(".meter-select-column-groups input[value='all']").parent().removeClass("active");

        // update active tabs list
        activeMeterTabs = allActiveMeterColumsGroup();

        // show table columns
        showTableMetersColumns(table, activeMeterTabs, allColumns);

        // Manually toggleClass .active because .button('toggle') removes it
        $(e.currentTarget).toggleClass('active');

        // save active state in backend
        updateUI(JSON.stringify({'meters_active_tabs': activeMeterTabs}));
      }
    });
  }
});

//
// Show / Hide Distribution Table static columns
//
$( document ).ready(function() {
  if($('.dataTable').length) {
    let table = $('.dataTable').DataTable();

    if ($('.dataTable').parents().eq(1).hasClass('table-distribution')) {
      // Distribution Table has an extra first row header and user can toggle only static columns
      let activeStaticColumns = $('.dataTable').parents().eq(1).data('static-colums').split(", ").filter(x => x != "");
      adjustStaticColumns(table, activeStaticColumns)
    } else {
      // Implementation for single table header row
    }

    // Prevent dropdown from closing
    $('.static-columns-dropdown li').on("click.bs.dropdown", function (e) {
      e.stopPropagation();
    });

    $('.static-columns-dropdown li').on('click', function (e) {
      let selectedColumns = []
      $(e.currentTarget.parentElement).find("input").each( function(index, element) {
        if (element.checked) { selectedColumns.push($(element).attr("name")); }
      })

      // refresh table on onclick
      adjustStaticColumns(table, selectedColumns)

      // update preferences
      updateUI(JSON.stringify({'dt_static_columns': selectedColumns}));
    });
  }
});

function adjustStaticColumns(table, activeStaticColumns) {
  let allStaticColumns = []
  let activeStaticColumnIndexes = []

  $(table.columns().header()).each( function( index, element ) {
    if ($(element).data("header-group") === undefined) { allStaticColumns.push(index); }
    if (activeStaticColumns.includes($(element).data("column-id"))) { activeStaticColumnIndexes.push(index); }
  });

  $('.datatable').DataTable().columns(allStaticColumns).visible(false);
  $('.datatable').DataTable().columns(activeStaticColumnIndexes).visible(true);

  // Adjust first table row with fix for colspan=0
  if (activeStaticColumns.length == 0) {
    $('.dataTable thead tr:first-child th:first-child').css("display", "none")
  } else {
    $('.dataTable thead tr:first-child th:first-child').css("display", "")
    $('.dataTable thead tr:first-child th:first-child').attr('colspan', activeStaticColumns.length)
  }
}

function allActiveMeterColumsGroup() {
  let activeMeterTabs = [];
  // make a list with all active tabs
  $('.meter-select-column-groups .active').each( (index, element) => {
    activeMeterTabs.push($(element).find("input").val());
  });
  return activeMeterTabs;
}

function allMeterColumsGroup() {
  let allTabs = [];
  // make a list with all meter tabs
  $('.meter-select-column-groups > label').not("active").each( (index, element) => {
    allTabs.push($(element).find("input").val());
  });
  return allTabs;
}

function showTableMetersColumns(table, activeTabs, allColumns) {
  let activeMeterColumns = [], meterTypes = [], staticColumns = []

  $(table.columns().header()).each( function( index, element ) {
    if ($(element).data("header-group") === undefined) {
      staticColumns.push(index);
    } else {
      meterTypes.push($(element).data("header-group"));
    }
    if (activeTabs.includes($(element).data("header-group"))) { activeMeterColumns.push(index) }
  })

  let allMetersColumns = allColumns.filter(x => !staticColumns.includes(x))
  let meterTypesHide = meterTypes.reduce((x, y) => x.includes(y) ? x : [...x, y], [])
                                 .filter(x => !activeTabs.includes(x));
  let firstRowHideTh = meterTypesHide.map( (e) => { return `.datatable th[data-type="${e}"]` }).join(', ');

  $('.datatable').DataTable().columns(allMetersColumns).visible(false);
  $('.datatable').DataTable().columns(activeMeterColumns).visible(true);
  for (let th of activeTabs) { $(`.datatable th[data-type="${th}"]`).show(); }
  $(firstRowHideTh).hide();
}

$(function() {
    $("#DataTables_Table_0").before(`
        <div class="row align-items-center py-2 dataTables-menu">
            <div class="col-auto">
                Showing
                <select class="align-middle" id="dataTables_length">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                records
            </div>

            <div class="col"></div>

            <div class="col-auto search-controls">
              <input id="dataTables_search" class="form-control form-control-sm" type="search" placeholder="Search..." />
            </div>

            <div class="col-auto export-controls">
              <div class="dropdown data-table-export-format">
                <button class="btn dropdown-toggle" type="button" id="dropdownExportButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="label">Export</span>
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownExportButton">
                  <li><a class="dropdown-item" data-value="csv" href="#">CSV</a></li>
                  <li><a class="dropdown-item" data-value="xlsx" href="#">XLSX</a></li>
                  <li><a class="dropdown-item" data-value="pdf" href="#">PDF</a></li>
                </div>
              </div>
            </div>
          </div>
        </div>
    `);

    $('.data-table-export-format li a').on('click', function() {
        var val = $(this).data("value");
        report_link(val);
    });

    $('#dataTables_length').val($(".datatable").DataTable().page.len());

    $('#dataTables_search').on('keyup', function() {
        $(".datatable").DataTable().columns().search($(this).val()).draw();
    });

    $('#dataTables_length').on('change',function(){
        $(".datatable").DataTable().page.len($(this).val()).draw() ;
    });

    /*
    * Filter popover
    */

    // try {
    //     $(".datatable").DataTable().columns()[0].map(function (column) {
    //         $('#filter-checkboxes').append(`
    //         <label class="custom-control custom-checkbox">
    //             <input type="checkbox" class="custom-control-input" value="${column} "checked>
    //             <span class="custom-control-indicator"></span>
    //             <span class="custom-control-description">${$(".datatable").DataTable().column(column).title()}</span>
    //         </label>
    //         <br/>
    //     `);
    //     });
    //
    //     $('#columns-to-search').popover({
    //         placement: 'bottom',
    //         html: true,
    //         content: function () {
    //             return $('#filter-checkboxes').children().clone();
    //         }
    //     });
    //
    //     $('#columns-to-search').on('hide.bs.popover', function () {
    //         let popover = $('#' + $(this).attr('aria-describedby'));
    //         $('#filter-checkboxes').html(popover.find('.popover-body').children().clone());
    //     });
    // }
    // catch(err) { console.log("error: ", err); }
//        $("#DataTables_Table_0_filter").append("&nbsp;&nbsp;&nbsp;<button class='btn btn-info btn-xs' onclick='report_link(\"csv\")'>{% trans 'CSV' %}</button>");
//        $("#DataTables_Table_0_filter").append("&nbsp;<button class='btn btn-info btn-xs' onclick='report_link(\"xlsx\")'>{% trans 'XLSX' %}</button>");
//        $("#DataTables_Table_0_filter").append("&nbsp;<button class='btn btn-info btn-xs' onclick='report_link(\"pdf\")'>{% trans 'PDF' %}</button>");
});


//
// Delete tag onclick
//
$( document ).on('click', '[id^="tag-"]', function () {
    let result = confirm(gettext("Are you sure you want to delete this tag?"));

    if (result) {
      let ids = $(this).attr('id').split('-');

      ajaxDelete(`/emeter/subscriber/remove_tag/${ids[1]}/${$(this).data('tag')}`)
        .done((response) => {
          $(".dataTable").DataTable().ajax.reload(null, false);
        })
        .fail((response) => {
          console.log("error", response);
          errorNotification(gettext("The tag could not be removed"));
        })
    }
});
