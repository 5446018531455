$(document).on('click', '.maintenance', function(e) {
  if (!this.href.includes('#!')) {
    e.preventDefault();
    $('#process-modal').modal({
      backdrop: 'static',
      keyboard: true
    }, 'show').find('.modal-body').load($(this).attr('href'), function (response, status, xhr) {
      if (status === "error") {
        $(this).html(`<center><b>${xhr.statusText}</b></center>`);
      }
    });
  }
});

$(document).on("submit", "#process-form", function (e) {
  e.preventDefault();
  let url = $(this).attr('action');

  $.ajax({
    type: 'POST',
    enctype: 'multipart/form-data',
    processData: false,
    contentType: false,
    url: url,
    data: new FormData($(this)[0])
  })
  .done(function (response) {
    $('#process-modal').modal('hide');

    $.notify(response['message'], {
      newest_on_top: true,
      type: "success",
      placement: {
        align: 'center'
      }
    });

    $("#process-form").serializeArray().forEach(function (el) {
      if (el['name'] == "installation_type") {
        let installation_type = el['value'];

        if (installation_type == "1") {
          $(".datatable").DataTable().ajax.reload(null, false);
        }
      }
    });
  })
  .fail(function (err) {
    console.error(err);

    if (err.status === 422) {
      let json = err.responseJSON;
      for (let key in json) {
        $(`#error-id_${key}`).html(json[key].join('<br>'));
        $(`#id_${key}`).attr('readonly', false);
        $(`#id_${key}`).parent().parent().parent().parent().collapse('show');
      }

      $.notify(gettext('Your form has errors'), {
        newest_on_top: true,
        z_index: 99999,
        type: "danger",
        placement: {
          align: 'center'
        }
      });
      return;
    }

    $.notify(err.responseJSON['err'], {
      newest_on_top: true,
      z_index: 99999,
      type: "danger",
      placement: {
        align: 'center'
      }
    });
  });
});
