import {errorNotification} from 'static/js/app'

$(function () {
  $('#disconnected-meters-info').popover({
    trigger: 'hover',
    container: "body",
    placement: "top",
    html: true,
    content: $('#disconnected-meters-popover-content').html()
  });

  $(document).on('click', '.meter-remove-slot', function (e) {
      e.preventDefault();
      var currentTarget = e.currentTarget;

      $.ajax({
          url: $(this).attr('href'),
          type: "DELETE"
      })
      .done(function(data) {
          if($(currentTarget).parents().hasClass("datatable")) {
            $(".datatable").DataTable().ajax.reload(null, false);
          }
          else {
            $(currentTarget).parents('.dp-meter-card').parent().remove();
          }
      })
      .fail(function(err) {
          errorNotification(err.statusText);
      })
  });

  $('body').on('keyup', '.typeahead-tag', function (e) {
      if (e.keyCode == 13) {
          addTagToSubscriber($(`span[aria-describedby='${$(e.currentTarget).parent().parent().attr('id')}']`), $(e.currentTarget).val());
      }
      else if (e.keyCode == 27) {
          $(`span[aria-describedby='${$(this).parent().parent().attr('id')}']`).popover('hide');
      }
  });

  $(document).on('click', '.close-popover-tag', function () {
      $(`span[aria-describedby='${$(this).parent().parent().attr('id')}']`).popover('hide');
  });
});
