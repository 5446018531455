let Meter = function(container, options) {
  this.container = $(container);

  this.options = {
    style: {
      general_class: "col-12 col-md-6 col-lg",
      meter_class: "primary"
    },
    messages: {
      disconnected: gettext("DISCONNECTED"),
      connect: gettext("Connect Meter"),
      disconnect: gettext("Disconnect Meter"),
      replace: gettext("Replace Meter"),
      manual: gettext("Manual Reading"),
      remove: gettext("Remove Meter Slot"),
      add: gettext("Add Meter"),
      not_exist: gettext("METER DOES NOT EXIST")
    },
    installation_medium: null,
    installation_medium_name: "N/A",
    meter_link: null
  };

  this.html = "";

  $.extend(true, this.options, options);

  this.init = function () {
    this.html = `
          <div class="${this.options.style.general_class}">
              <div class="card my-1 dp-meter-card card-small ${this.options.style.meter_class}-card">
                  <div class="card-body">
                      <div class="row justify-content-center card-title">
                          <a data-toggle="collapse" href="#${slugify(this.options.installation_medium_name)}" aria-expanded="false"
                              aria-controls="${slugify(this.options.installation_medium_name)}"
                              class="text-center">
                              ${this.options.installation_medium_name}
                          </a>
                          <span class="status-circle align-middle ml-2 d-none"></span>
                      </div>
                      <div class="row meter-content">
                          <div class="col d-flex align-items-center flex-column">
                              <img src="/static/images/ring-loading.gif" class="meter-loading"/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        `;

    this.html = $(this.html);
    this.container.append(this.html);
  };

  this.update_meter_data = function (link) {
    if (!link) {
      let wrong_link = gettext("Wrong link");
      this.html.find('.meter-content div').html(`
              <div class="d-flex justify-content-around mb-2">${wrong_link}</div>
            `);
      return;
    }

    $.ajax({
      method: "GET",
      url: link,
      dataType: "json",
    })
    .done((data) => {
      if (data.serial_number) {
        this.html.find('.status-circle').removeClass('d-none').addClass(data.status);

        let info = `
        <div class="d-flex justify-content-around card-serial-number">
            <a href="${data.meter_data_link}">
                SN ${data.serial_number}
            </a>
        </div>
        <div class="d-flex justify-content-around card-serial-number mb-2">
            INV ${data.inventory_no}
        </div>`;

        let actions = `
        <div id="${slugify(this.options.installation_medium_name)}" class="collapse">
            <a class="maintenance"
                href="${data.remove_link}"
                data-toggle="tooltip" data-placement="top"
                title="${this.options.messages.disconnect}">
                <span class="icon-contor meter-disconnect"></span>
            </a>
            <a class="add-manual-reading" href="#"
                data-meter="${data.serial_number}"
                data-toggle="tooltip" data-placement="top"
                title="${this.options.messages.manual}">
                <span class="icon-contor manual-reading"></span>
            </a>
        </div>`;

        let indices = $.map(data.indices, function (index) {
          return `<div class="d-flex justify-content-around card-index">${index['value']}</div>`;
        }).join("");

        this.html.find('.meter-content div').html(info + actions + indices);
      }
      else {
        let info = `<div class="d-flex justify-content-around mb-2">${this.options.messages.disconnected}</div>`;
        let actions = `
        <div>
            <a class="maintenance"
                href="${data.install_link}"
                data-toggle="tooltip" data-placement="top"
                title="${this.options.messages.connect}">
                <span class="icon-contor meter-connect"></span>
            </a>
            <a class="meter-remove-slot"
                href="${data.remove_slot_link}"
                data-toggle="tooltip" data-placement="top"
                title="${this.options.messages.remove}">
                <span class="fa fa-times"></span>
            </a>
        </div>
      `;
        this.html.find('.meter-content div').html(info + actions);
      }
    })
    .fail((request, errorCode, err) => {
      let error = gettext("ERROR");
      if(request.status === 0) {
        let serverIsUnreachable = gettext("The server is unreachable");
        err = {
          "responseJSON": {
            "err": serverIsUnreachable
          }
        }
      }
      this.html.find('.meter-content div').html(`
        <div class="d-flex justify-content-around mb-2">${error}</div>
        <div class="d-flex justify-content-around mb-2">${err.responseJSON.err}</div>
      `);
    })
    .always(() => {
      window.setTimeout(() => {
        this.update_meter_data(this.options.meter_link)
      }, 5000);
    });
  };

  this.init();
  this.update_meter_data(this.options.meter_link);

  function slugify(string) {
    return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
  }
};

module.exports = { Meter: Meter }
