import {updateUI} from 'static/js/ajax'

$(document).ready(function () {
  $('#sidebarCollapse').on('click', function () {
    // Set .active class to sidebar
    $('#sidebar').toggleClass('active');

    // Adjust FixedHeader for DataTables
    $('.dataTable').DataTable().fixedHeader.adjust()

    // Save sidebar state in backend
    updateUI(JSON.stringify({
      'sidebar_collapsed': $('#sidebar').hasClass('active')
    }))
  });

  $('.navbar-toggler').on('click', function() {
    if ($('#sidebar').hasClass('d-none')) {
      $('#sidebar').removeClass('d-none');
    } else {
      $('#sidebar').addClass('d-none');
    }
  });
});

$(document).ready(function () {
  var current = location.pathname;

  $('#sidebar .nav li a').each(function(){
    var $this = $(this);

    // if the current path is like this link, make it active
    if(current.indexOf($this.attr('href')) !== -1){
      $this.addClass('active');
    }
  });

  $('[data-toggle="tooltip"]').tooltip();
});

$(document).on('click', '.leaflet-right', function(){
  $('#locationSearch').focus();
});

export function errorNotification(message) {
  $.notify(message, {
    newest_on_top: true,
    type: "danger",
    placement: { align: 'center' },
    z_index: 10000,
  });
}
