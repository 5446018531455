$(document).ready(function () {
  $(document).on('click', '#add-manual-reading, .add-manual-reading', function (e) {
    e.preventDefault();

    let url = "/emeter/manual-reading/modal/";
    if ($(this).data('meter')) {
      url = `/emeter/manual-reading/meter/${$(this).data('meter')}/modal/`;
    }

    $('#manual-reading-modal').modal({
      backdrop: 'static',
      keyboard: true
    }, 'show').find('.modal-body').load(url, function (response, status, xhr) {
      if (status === "error") {
        let error_text = gettext("The form cannot be loaded");
        $(this).html(`<center><b>${error_text}</b></center>`);
        return;
      }

      $('#reading-date').daterangepicker({
        parentEl: "#manual-reading-modal",
        locale: {
          format: "DD/MM/YYYY HH:mm"
        },
        autoApply: true,
        timePicker: true,
        timePicker24Hour: true,
        singleDatePicker: true,
        opens: "center",
        maxDate: moment().format("DD/MM/YYYY HH:mm"),
      });
      $('#reading-date').data('daterangepicker').setStartDate(moment().format('DD/MM/YYYY HH:mm'));

      $('#manual-date-checkbox').on('change', function () {
        $('#reading-date').prop('readonly', !$(this).is(':checked'));
      });

      $('#meterSearch').typeahead({
        source: function (query, process) {
          return $.ajax({
            url: $('#meterSearch').data('link'),
            type: 'get',
            data: {query: query},
            dataType: 'json',
            success: function (result) {
              let resultList = result.map(function (item) {
                let aItem = {
                  id: item.id,
                  text: item.text,
                  properties: item.properties,
                };
                return JSON.stringify(aItem);
              });
              return process(resultList);
            }
          });
        },

        highlighter: function (obj) {
          let item = JSON.parse(obj);
          return item.text;
        },

        updater: function (obj) {
          let item = JSON.parse(obj);
          let html = "";
          item.properties.forEach(function (el, i) {
            html += `
                    <div class="form-group text-center">
                        <label>${el.measurement} (${el.manual_reading_unit})</label>
                        <input class="form-control text-center" type="number" min="0" name="value${i}"
                            data-measurement="${el.measurement}"
                            data-unit="${el.manual_reading_unit}" step="0.0001" required"/>
                    </div>
               `;
          });
          $('#add_record_form div.form-group').slice(2).remove();
          $('#submit-manual-reading-form').before(html);

          return item.text;
        },

        matcher: function (obj) {
          return true;
        },
      });

      $('#add-record-form').on('submit', function (e) {
        e.preventDefault();
        send_form();
      });

      function send_form() {
        let post_json = {
          meter: $('#meterSearch').val(),
          reading_timestamp: moment($('#reading-date').val(), "DD/MM/YYYY hh:mm").toISOString(),
          values: []
        };
        $('#add-record-form input').not(':hidden').slice(3).not(':submit').each(function (i, el) {
          post_json['values'].push({
            name: $(el).attr('name'),
            value: $(el).val(),
            measurement: $(el).attr('data-measurement'),
            unit: $(el).attr('data-unit'),
          });
        });

        $.ajax({
            type: 'POST',
            url: $('#submit-manual-reading-form').data('link'),
            data: JSON.stringify(post_json)
          })
          .done(function (data) {
            $('#manual-reading-modal').modal('hide');
            $('#add-record-form .form-group').slice(2).remove();
            $('#meterSearch').val("");

            if ($(".datatable")) {
              $(".datatable").DataTable().ajax.reload(null, false);
            }

            $.notify(gettext("The manual reading has been registered"), {
              newest_on_top: true,
              placement: {
                align: 'center'
              }
            });

          })
          .fail(function (err) {
            if (err.status === 500) {
              errorNotification(gettext("The application cannot save the reading"));
            }
            else {
              alert(err.responseJSON.err_msg);
            }
          });
      }
    });
  });
});
