let MeterActions = {

  renderDropdown() {
    $('.datatable').on('click', '.sn-dropdown', (e) => {
      let dropdownMenu = $(this.dropdownMenuRight())
      let serialNumber = $(e.currentTarget).data("serial-number")

      let links = dropdownMenu.append(this.disconnectMeterLink(serialNumber))
                              // .append(this.replaceMeterLink(serialNumber))

      if ($(e.currentTarget).data("meter-type") == 0) {
        links = links.append(this.manualReadingLink(serialNumber))
      }

      $(e.currentTarget.parentElement).append(links)
    })

    $('.datatable').on('click', '.disconnected-dropdown', (e) => {
      let dropdownMenu = $(this.dropdownMenuRight())
      let meterSlot = $(e.currentTarget).data("meter-slot")

      let links = dropdownMenu.append(this.connectMeterLink(meterSlot))
                              .append(this.removeMeterSlotLink(meterSlot))

      $(e.currentTarget.parentElement).append(links)
    })
  },

  dropdownMenuRight() {
    return '<div class="dropdown-menu dropdown-menu-right"></div>'
  },

  connectMeterLink(meterSlot) {
    let connectMeter = gettext("Connect Meter")
    return `
      <a class="dropdown-item maintenance" href="/maintenance/process_type/0/add-meter-modal/?meter_slot=${meterSlot}">
        <span class="icon-contor meter-connect"></span>${connectMeter}
      </a>
    `
  },

  disconnectMeterLink(serialNumber) {
    let disconnectMeter = gettext("Disconnect Meter")
    return `
      <a class="dropdown-item maintenance" href="/maintenance/process_type/1/remove-meter-modal/?serial_number=${serialNumber}">
        <span class="icon-contor meter-disconnect"></span>${disconnectMeter}
      </a>
    `
  },

  replaceMeterLink(serialNumber) {
    let replaceMeter = gettext("Replace Meter")
    return `
      <a class="dropdown-item maintenance" href="INLOCUIRE_HREF">
        <span class="icon-contor meter-replace"></span>${replaceMeter}
      </a>
    `
  },

  manualReadingLink(serialNumber) {
    let manualReading = gettext("Manual Reading");
    return `
      <a class="dropdown-item add-manual-reading" href="" data-meter="${serialNumber}">
        <span class="icon-contor manual-reading"></span>${manualReading}
      </a>
    `
  },

  removeMeterSlotLink(meterSlot) {
    let removeMeterSlot = gettext("Remove Meter Slot")
    return `
      <a class="dropdown-item meter-remove-slot" href="/emeter/api/remove_meter_slot/${meterSlot}/">
        <span class="fa fa-times"></span>${removeMeterSlot}
      </a>
    `
  },
}

module.exports = { MeterActions: MeterActions }
