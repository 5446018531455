$(document).ready(function () {
  $('#unmetered-data-from').on('submit', function (e) {
    e.preventDefault();

    $.ajax({
      type: 'POST',
      dataType: 'json',
      enctype: 'multipart/form-data',
      processData: false,
      contentType: false,
      mimeType: 'multipart/form-data',
      url: $('#unmetered-data-from').attr('action'),
      data: new FormData($("#unmetered-data-from")[0])
    })
    .done(function (data) {
      console.log(data);
      if (data['message'] === "success") {
        $('add-unmetered-data-modal').modal('hide');
        location.reload();
      }
      else {
        alert(data['message']);
      }
    })
    .fail(function (err) {
      console.log(err);
      alert(err.responseJSON['message']);
    });
  });

  $('#month-year-picker').datepicker({
    format: "mm/yyyy",
    minViewMode: 1,
    autoclose: true
  });
});
