$(document).ready(function () {
  $(document).on('click', '#add-report', function(e) {
    e.preventDefault();
    $('#add-report-modal').modal({
        backdrop: 'static',
        keyboard: true
    }, 'show');
    $('#report-type').val('1');
    $('#report-type').attr('readonly', true);
    $('#report-type').change();
  });

  $('#report-type').on('change', function() {
    switch($(this).val()) {
      case "1":
        $('#production-report-form').show();
    }
  });

  $('#interval').val(moment().subtract(1,'months').startOf('month').format('DD/MM/YYYY') + " - " + moment().subtract(1,'months').endOf('month').format('DD/MM/YYYY'));
  $('#interval').daterangepicker({
    locale: {
        format: "DD/MM/YYYY"
    },
    dateLimit: {
        months: 1
    },
    opens: "center",
    maxDate: moment().format("DD/MM/YYYY"),
  });

  var webSocket = new WebSocket('ws' + location.protocol.substring(4) + '//' + window.location.host + '/ws/report/generate_production_report/');

  webSocket.onmessage = function(e) {
    var data = JSON.parse(e.data);
    if(data['action'] === 'complete') {
      $('#add-report-modal').modal('hide');
      $.notify(data["message"], {
        newest_on_top: true,
        type: "success",
        placement: {
          align: 'center'
        }
      });

      $('#report-loading').hide();
      $('#submit-production-report-form').show();
      $(".datatable").DataTable().ajax.reload(null, false);
    }
  };

  webSocket.onclose = function(e) {
    console.error('WS closed:', e);
    $('#report-loading').hide();
    if(e.code !== 1000) {
      alert("Error while generating the report. Refresh the page before another attempt");
    }
  };

  webSocket.onerror = function(e) {
    console.log("WS error:", e);
    $.notify(e, {
      newest_on_top: true,
      type: "danger",
      placement: {
        align: 'center'
      }
    });
  };

  $('#submit-production-report-form').on('click', function () {
    var result = { };
    $.each($('#production-report-form').serializeArray(), function() {
      result[this.name] = this.value;
    });
    webSocket.send(JSON.stringify(result));
    $('#report-loading').show();
    $('#submit-production-report-form').hide();
  });
});
