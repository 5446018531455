export function updateUI(data) {
  return ajaxRequest('/account/ui/', "PATCH", data)
}

export function ajaxCreateModal(url, data) {
  return ajaxRequest(url, "POST", data)
}

export function ajaxUpdateModal(url, data) {
  return ajaxRequest(url, "PUT", data)
}

export function ajaxDelete(url) {
  return $.ajax({
    url: url,
    type: "DELETE"
  });
}

// Setup Authentication token on document ready
$(document).ready(function() {
  let csrftoken = getCookie('csrftoken');

  jQuery.ajaxSetup({
    beforeSend: function(xhr, settings) {
      if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
        xhr.setRequestHeader("X-CSRFToken", csrftoken);
      }
    }
  });
});

function ajaxRequest(url, method, data) {
  return $.ajax({
    url: url,
    type: method,
    dataType: "json",
    contentType: "application/json",
    data: data
  });
}

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = jQuery.trim(cookies[i]);
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}
