import { errorNotification } from 'static/js/app'
import { formatDateTime } from 'static/js/utils'


$(function () {
  $(".remote-operations").detach().insertBefore(".table-distribution .search-controls")

  $('#dhemconControlButtonModal').on('shown.bs.modal', function (e) {
    // Remove all content from table
    $(".rpc-jobs tbody").empty();

    // Register Tooltip
    $('.rpc-jobs').tooltip({ selector: '[data-toggle="tooltip"]', placement: 'bottom', html: true });

    let gatewaySerialNumber = $(e.relatedTarget).data("gateway-serial-number")
    var webSocket = new WebSocket('ws' + location.protocol.substring(4) + '//' + window.location.host + '/ws/emeter/gateway_rpc/' + gatewaySerialNumber + '/');

    webSocket.onopen = function (e) { console.log('WS opened:', e); };

    webSocket.onmessage = function (e) {
      let data = JSON.parse(e.data);

      console.log("incoming data", data);

      if (data.action == "error") {
        errorNotification(data.message);
      } else if (data.action == "initial_data") {
        renderInitialData(data)
      } else {
        updateTable(data.rpc_job)
      }
    };

    webSocket.onclose = function (e) {
      console.error('WS closed:', e);
      if (e.code !== 1000) {
        errorNotification(gettext("Error connecting to websocket server"));
      }
    };

    webSocket.onerror = function (e) {
      console.log("WS error:", e);
      errorNotification(e);
    };

    $('#dhemconControlButtonModal .navbar button').on('click', function (e) {
      var data_request = $(e.currentTarget).data('request-type');
      if (data_request == 'scan-mbus') {
        var data = JSON.stringify({
          "action": "ws_request",
          "method": "SCAN_MBUS",
          "params": null
        })

      } else if(data_request == 'read-all-meters') {
        var data = JSON.stringify({
          "action": "rpc_request",
          "method": "READ_ALL_METERS",
          "params": null
        })
      } else if(data_request == 'ping') {
        var data = JSON.stringify({
          "action": "rpc_request",
          "method": "PING",
          "params": null
        })
      }
      webSocket.send(data);
    });

    $('#dhemconControlButtonModal .table').on('click', '.btn', function (e) {
      let method = e.currentTarget.dataset.method
      let jobId = e.currentTarget.parentElement.parentElement.dataset.jobId

      var data = JSON.stringify({
        "action": "rpc_request",
        "method": method,
        "params": {
          "job_id": parseInt(jobId)
        }
      })

      webSocket.send(data);
    });
  });
});

function renderInitialData(data) {
  let tbody = ''

  for (let rowData of data.rpc_jobs) {
    tbody += tableRow(rowData)
  }

  $('.rpc-jobs').find('tbody').append(tbody)
}

function updateTable(data) {
  if ($(`.rpc-jobs tr[data-job-id="${data.job_id}"]`).length > 0) {
    updateTableRow(data);
  } else {
    addTableRow(data);
  }
}

function addTableRow(data) {
  let newRow = $(tableRow(data)).hide();
  newRow.prependTo('.rpc-jobs tbody').fadeIn(300);
}

function updateTableRow(data) {
  $(`.rpc-jobs tr[data-job-id="${data.job_id}"]`).fadeOut(300, function() {
    let updatedRow = $(tableRow(data)).hide();
    $(this).replaceWith(updatedRow);
    $(`.rpc-jobs tr[data-job-id="${data.job_id}"]`).fadeIn(300);
  });
}

function tableRow(rowData) {
  if (!rowData.response) { rowData.response = { "result": "N/A" } }

  return `
    <tr data-job-id="${rowData.job_id}">
        <td class="align-middle">${rowData.job_id}</td>
        <td class="align-middle">${rowData.created_by}</td>
        <td class="align-middle">${formatDateTime(rowData.created_at)}</td>
        <td class="align-middle">${formatDateTime(rowData.finished_at)}</td>
        <td class="align-middle">${rowData.method}</td>
        <td class="align-middle">${rowData.status}</td>
        <td class="align-middle" data-toggle="tooltip" title="${responseTooltip(rowData.response)}">
          <span class="d-inline-block text-truncate" style="max-width: 350px;">${responseData(rowData.response)}</span>
        </td>
        <td class="align-middle">${renderActionButton(rowData)}</td>
    </tr>
  `
}

function renderActionButton(rowData) {
  if (!["FINISHED", "CANCELING"].includes(rowData.status)) {
    return `
        <button class="btn btn-primary btn-sm" type="button" data-method="CANCEL">
            ${gettext("Cancel")}
        </button>
    `
  } else {
    return ""
  }
}

function responseData(response) {
  let tooltipLines = ""

  if (response.result && response.result instanceof Object) {
    let string = JSON.stringify(response.result)
    return `<span>${string}</span>`
  } else {
    return response.result || response.error
  }
}

function responseTooltip(response) {
  let tooltipLines = ""

  if (response.result && response.result instanceof Object) {
    for (let item of response.result) {
      let itemLine = ""

      for (let [k, v] of Object.entries(item)) {
        itemLine += `<span><strong>${k}:</strong> ${v}<span> </ br>`
      }

      tooltipLines += `<div>${itemLine}</div>`
    }

    return `<div>${tooltipLines}</div>`
  } else {
    return response.result || response.error
  }
}
