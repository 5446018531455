$( document ).ready(function () {
  $('input[name="daterange"]').daterangepicker({
      locale: { format: "DD/MM/YYYY" },
      autoApply: true,
      opens: "left",
      maxDate: moment().format("DD/MM/YYYY"),
  });
  $('input[name="daterange"]').data('daterangepicker').setStartDate(moment().startOf('month').format('DD/MM/YYYY'));
  $('input[name="daterange"]').data('daterangepicker').setEndDate(moment().format('DD/MM/YYYY'));

  // Set first list element active
  $('.meter-medium-type .meter').first().addClass("active")

  $('.meter-medium-type .meter').on('click', function (e) {
      updateDatatable();
  })

  $('#interval-type, input[name="daterange"]').on('change', function() {
      updateChart();
      updateDatatable();
  });

  updateChart();
});

function updateDatatable() {
  let meterType = $('.meter-medium-type .meter.active').find('input').val()
  var get = $(location).attr('href') + `?interval=${encodeURI($('input[name="daterange"]').val())}&meter_type=${meterType}`;
  $(".datatable").DataTable().ajax.url(get).load();
}

function updateChart() {
  let subscriberId = $('#chart-container').data('subscriber-id')

  // Get the data and create the chart
  $.getJSON(`/emeter/meters/${subscriberId}/consumption_data/?interval=${$('input[name="daterange"]').val()}&interval_type=${$('#interval-type').val()}`, function (json) {
    Highcharts.stockChart('chart-container', {
      chart: {
        "zoomType": "x",
        "type": "column"
      },
      rangeSelector: {
        enabled: false,
      },
      title: {
        text: gettext('Consumption data')
      },
      plotOptions: {
        column: {
          keys: ['name', 'y'],
          grouping: true
        },
        series: {
          showInNavigator: true,
          pointWidth: 10
        }
      },
      legend: {
        enabled: true
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%e. %b',
          year: '%b'
        },
        labels: {
          align: 'center',
          // TODO: fix that f*****g timezone
          min: moment($('input[name="daterange"]').val().split(' - ')[0], 'DD/MM/YYYY').toDate().getTime() + 7200000,
          max: moment($('input[name="daterange"]').val().split(' - ')[1], 'DD/MM/YYYY').toDate().getTime() + 7200000,
          tickInterval: 3600 * 1000 * $("#interval-type").find(":selected").data('tick'),
        }
      },
      yAxis: json.yAxis,
      series: json.data
    });
  });
}
