let ProcessForm = {

  init() {
    this.openEmptyFormTabs();
    this.setRemovalDate();
    registerAutocompleteOnDistributionPoint();
    registerAutocompleteOnMeterUUID();
    registerAutocompleteOnMeasurementPoint();
    registerEventsOnInputChange();
  },

  openEmptyFormTabs() {
    $('#process-form input:not([readonly]):not([type=hidden])').each(function(index) {
      $(this).parent().parent().parent().parent().collapse('show');
    });
  },

  setRemovalDate() {
    $('#id_removal_date').daterangepicker({
      parentEl: "#process-form",
      locale: { format: "DD/MM/YYYY HH:mm" },
      autoApply: true,
      timePicker: true,
      timePicker24Hour: true,
      singleDatePicker: true,
      showDropdowns: true,
      opens: "center",
      maxDate: moment().format("DD/MM/YYYY HH:mm"),
      startDate: moment().format('DD/MM/YYYY HH:mm')
    });
  }
};

module.exports = { ProcessForm: ProcessForm }

// TODO: check if you really need it when someone paste the input
// Consider using on focus out event and validate input value on backend
// $(function() {
//   $('#id_equipment_uuid').on('paste', function () {
//     $('#id_system_part').attr('readonly', false);
//     $('#id_system_part_temp_probe').attr('readonly', false);
//     $('#id_system_part_flowmeter').attr('readonly', false);
//     $('#id_inventory_number').attr('readonly', false);
//     $('#id_lease_type').attr('readonly', false);
//     $('#id_equipment_seals').attr('readonly', false);
//   });
// });

// Autocomplete the meter UUID input
function registerAutocompleteOnMeterUUID() {
  $('#id_equipment_uuid').typeahead({
    source: function (query, process) {
      return $.ajax({
        url: "/maintenance/autocomplete/meter_autocomplete_search/",
        type: 'get',
        data: {
          query: query,
          process_type: $('#id_process_type').val(),
          meter_type: $('#id_meter_type').val(),
          installation_medium: $('#id_installation_medium').val(),
        },
        dataType: 'json',
        success: function (result) {
          let resultList = result.map(function (item) {
            let aItem = {
              id: item.id,
              text: item.text,
              address: item.address
            };
            return JSON.stringify(aItem);
          });
          return process(resultList);
        },
      });
    },

    highlighter: function (obj) {
      let item = JSON.parse(obj);
      return item.text;
    },

    updater: function (obj) {
      let item = JSON.parse(obj);
      $('#id_system_part').attr('readonly', false);
      $('#id_system_part_temp_probe').attr('readonly', false);
      $('#id_system_part_flowmeter').attr('readonly', false);
      $('#id_inventory_number').attr('readonly', false);
      $('#id_lease_type').attr('readonly', false);
      $('#id_equipment_seals').attr('readonly', false);

      if ($('#id_measurement_point').prop('') == false) {
        $('#id_measurement_point').val(item.address);
      }

      // TODO: update endpoint to work with all meters. Related to function: #check_installation_medium_value
      // $.ajax({
      //   url: "/maintenance/autocomplete/meter_autocomplete_data/",
      //   type: "get",
      //   data: {meter_id: item.id, installation_medium: $('#id_installation_medium').val()},
      //   dataType: 'json',
      //   success: function (data) {
      //     Object.keys(data).forEach(function (key) {
      //       $(`#id_${key}`).val(data[key]);
      //     });
      //   }
      // });

      return item.text;
    },

    matcher: function (obj) {
      return true;
    },
  });
}

// Autocomplete the measurement point value
function registerAutocompleteOnMeasurementPoint() {
  $('#id_measurement_point').typeahead({
    source: function (query, process) {
      return $.ajax({
        url: "/emeter/autocomplete/subscriber_autocomplete_search/",
        type: 'get',
        data: {query: query, dp: $('#id_distribution_point').text()},
        dataType: 'json',
        success: function (result) {
          let resultList = result.map(function (item) {
            let aItem = {
              id: item.id,
              text: item.address
            };
            return JSON.stringify(aItem);
          });
          return process(resultList);
        }
      });
    },

    highlighter: function (obj) {
      let item = JSON.parse(obj);
      return item.text;
    },

    updater: function (obj) {
      let item = JSON.parse(obj);
      $.ajax({
        url: `/emeter/autocomplete/beneficiary_data/subscriber/${item.id}/`,
        type: 'get',
        dataType: 'json',
        success: function (result) {
          $('#id_beneficiary').val(result.name);
          $('#id_beneficiary_address').val(result.address);
          $('#id_beneficiary_phone').val(result.phone);
        }
      });
      $('#id_installation_type').attr('readonly', false);
      $('#id_measurement_point').attr('readonly', true);

      return item.text;
    },

    matcher: function (obj) {
      return true;
    },
  });

  $('#id_subscriber').typeahead({
    source: function (query, process) {
      return $.ajax({
        url: "/emeter/autocomplete/subscriber_autocomplete_search/",
        type: 'get',
        data: {query: query},
        dataType: 'json',
        success: function (result) {
          let resultList = result.map(function (item) {
            let aItem = {
              id: item.id,
              text: item.name,
              address: item.address
            };
            return JSON.stringify(aItem);
          });
          return process(resultList);
        }
      });
    },

    highlighter: function (obj) {
      let item = JSON.parse(obj);
      return item.text;
    },

    updater: function (obj) {
      let item = JSON.parse(obj);
      $('#id_subscriber_address').val(item.address);
      return item.text;
    },

    matcher: function (obj) {
      return true;
    },
  });
}

// Autocomplete the distribution point value
function registerAutocompleteOnDistributionPoint() {
  $('#id_distribution_point').typeahead({
    source: function (query, process) {
      return $.ajax({
        url: "/emeter/autocomplete/distribution_point_autocomplete_search/",
        type: 'get',
        data: {query: query},
        dataType: 'json',
        success: function (result) {
          let resultList = result.map(function (item) {
            let aItem = {
              id: item.id,
              text: item.name
            };
            return JSON.stringify(aItem);
          });
          return process(resultList);
        }
      });
    },

    highlighter: function (obj) {
      let item = JSON.parse(obj);
      return item.text;
    },

    updater: function (obj) {
      let item = JSON.parse(obj);
      $('#id_measurement_point').attr('readonly', false);
      $('#id_distribution_point').attr('readonly', true);
      return item.text;
    },

    matcher: function (obj) {
      return true;
    },
  });
}

// Adds events on inputs and make them readonly, required, hidden, shown
function registerEventsOnInputChange() {
  $("#id_metrological_date").datepicker({
    dateFormat: "dd/mm/yy"
  });

  function check_system_part_value() {
    $('[id^=id_system_part_]').parent().hide();

    switch ($('#id_system_part').val()) {
      case "0":
        $('[id^=id_system_part_]').parent().show();
        break;
      case "1":
//      $('#id_equipment_uuid').attr('readonly', false);
//      $('#id_equipment_uuid').val("");
        break;
      case "2":
        $('[id^=id_system_part_flowmeter]').parent().show();
        break;
      case "3":
        $('[id^=id_system_part_temp_probe]').parent().show();
        break;
    }
  }

  $("#id_system_part").on('change', function (e) {
    check_system_part_value();
  });

  if (!$("#id_system_part").val()) {
    $("#id_system_part").val(0);
  }
  check_system_part_value();

  // TODO: find a way to make this dynamic
  // Initial this worked with 2 meter types.
  // Possible solution: make a get request to backend and get form fields for the meter
  // and append data to modal
  function check_installation_medium_value() {
    // let hot_water = "{% trans 'Hot water' %}".toLowerCase().replace(/ /g, '-') + "-group";
    // let heating = "{% trans 'Heating' %}".toLowerCase().replace(/ /g, '-') + "-group";
    // hot_water = hot_water.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    // heating = heating.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    // $(`[href="#${heating}"]`).parent().parent().parent().parent().hide();
    // $(`[href="#${hot_water}"]`).parent().parent().parent().parent().hide();

    switch ($('#id_installation_medium').val()) {
      case "4":
      // case "15":
        $(".card-content").eq(-1).hide();
        break;
      case "6":
      // case "7":
      // case "16":
        $(".card-content").eq(-2).hide();
        break;
    }
  }

  $("#id_installation_medium").on('change', function (e) {
    check_installation_medium_value();
    $('#id_installation_medium').attr('readonly', true);

    switch ($('#id_installation_type').val()) {
      case "1":
        $('#id_meter_type').attr('readonly', false);
        break;
      case "2":
        $('#id_equipment_uuid').attr('readonly', false);
        break;
    }
  });
  $('#id_installation_type option')[1].remove();
  check_installation_medium_value();

  function check_meter_type_value() {
    let value = $('#id_meter_type').val().toString();

    switch (value) {
      case "":
        $('#id_equipment_uuid').attr('readonly', true);
        $('#id_vm_formula').parent().hide();
        $('#id_vm_secondary_formula').parent().hide();
        $('#id_vm_secondary_formula').parent().hide();
        $('#id_vm_secondary_formula').parent().hide();
        break;
      case "0":
        $('#id_equipment_uuid').attr('readonly', false);
        $('#id_equipment_uuid').parent().show();
        $('#id_equipment_uuid').attr('requierd', true);

        $('#id_system_part').parent().show();
        $('#id_inventory_number').parent().show();
        $('#id_lease_type').parent().show();
        $('#id_lease_type').attr('required', true);
        $('#id_equipment_seals').parent().show();

        $('#id_vm_formula').parent().hide();
        $('#id_vm_formula').attr('required', false);

        $('#id_vm_secondary_formula').parent().hide();
        $('#id_vm_secondary_formula').attr('required', false);
        break;
      case "1":
        $('#id_equipment_uuid').attr('required', false);
        $('#id_equipment_uuid').parent().hide();

        $('#id_vm_formula').parent().show();
        $('#id_vm_formula').attr('required', true);
        if ($('#id_installation_medium').val() == "6") {
          $('#id_vm_secondary_formula').parent().show();
          $('#id_vm_secondary_formula').attr('required', true);
        }

        $('#id_system_part').parent().hide();
        $('#id_inventory_number').parent().hide();
        $('#id_lease_type').parent().hide();
        $('#id_lease_type').attr('required', false);
        $('#id_equipment_seals').parent().hide();
        $('#id_system_part_flowmeter').parent().hide();
        $('#id_system_part_temp_probe').parent().hide();
        break;
    }
  }

  $('#id_meter_type').on('change', function () {
    check_meter_type_value();
    $(this).attr('readonly', true);
  });
  if ($('#id_meter_type').val()) {
    $('#id_meter_type').attr('readonly', true);
  }
  check_meter_type_value();

  $('#id_installation_type').on('change', function () {
    let value = $(this).val();
    $(this).attr('readonly', true);
    $('#id_installation_medium').attr('readonly', false);

    switch (value) {
      case "1":
        $("#id_installation_medium option[value='7'], option[value='15'], option[value='16'], option[value='17']").remove();
        break;
      case "2":
        $('#id_meter_type').val("0");
        $('#id_meter_type').parent().hide();
        break;
    }
  });

  if ($('#id_process_type').val() == "0") {
    $('#id_meter_type').attr('readonly', false);
  }

  if($('#id_equipment_uuid').val() !== "") {
    $('#id_equipment_uuid').attr('readonly', true);
  }
}
